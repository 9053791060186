import { useCallback } from 'react'

import { useAppDispatch, useAppSelector } from '@/store'
import { createUser, fetchUser, selectUser } from '@/store/user'
import { ClientUser } from '@/types'

export const useFetchUser = () => {
  const dispatch = useAppDispatch()

  const getUser = useCallback(
    (uid: string) => {
      return dispatch(fetchUser({ uid }))
    },
    [dispatch],
  )

  return getUser
}

export const useCurrentUser = () => {
  const { user } = useAppSelector(selectUser)
  return user
}

export const useCreateUser = () => {
  const dispatch = useAppDispatch()

  const createNewUser = useCallback(
    (fields: Partial<ClientUser>) => dispatch(createUser({ fields })),
    [dispatch],
  )

  return createNewUser
}
