import { ConfigProvider } from 'antd'
import 'antd/dist/reset.css'
import { RouterProvider } from 'react-router-dom'

import AuthProvider from './components/AuthProvider'
import router from './router'
import THEME from './theme'

function App() {
  return (
    <ConfigProvider theme={THEME}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </ConfigProvider>
  )
}

export default App
