import { Campaign } from '@/types'

import { fetchAPI } from './base'

export const getCampaignsAPI = () => {
  return fetchAPI(`campaigns`, {
    method: 'GET',
  })
}

export const getCampaignAPI = (id: string) => {
  return fetchAPI(`campaigns/${id}`, {
    method: 'GET',
  })
}

export const updateCampaignAPI = (fields: Partial<Campaign>) => {
  const body = JSON.stringify(fields)
  return fetchAPI(`campaigns/${fields.id}`, {
    body,
    method: 'PUT',
  })
}

export const createCampaignAPI = (fields: Partial<Campaign>) => {
  const body = JSON.stringify(fields)
  return fetchAPI('campaigns', {
    body,
    method: 'POST',
  })
}
