import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { createCampaignAPI, getCampaignAPI, getCampaignsAPI, updateCampaignAPI } from '@/api'
import { type Campaign } from '@/types'

import type { RootState } from '.'

type CampaignStorage = { [id: string]: Campaign }

type CampaignState = {
  error: string | null
  isLoading: boolean
  campaigns: CampaignStorage
}

const slice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(fetchCampaigns.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCampaigns.fulfilled, (state, action) => {
        state.error = null
        state.isLoading = false
        state.campaigns = action.payload.reduce((acc, cur) => {
          acc[cur.id] = cur
          return acc
        }, {} as CampaignStorage)
      })
      .addCase(fetchCampaigns.rejected, (state, action) => {
        state.error = action.payload || ''
        state.isLoading = false
      })
      .addCase(fetchCampaign.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCampaign.fulfilled, (state, action) => {
        state.error = null
        state.isLoading = false
        state.campaigns[action.payload.id] = action.payload
      })
      .addCase(fetchCampaign.rejected, (state, action) => {
        state.error = action.payload || ''
        state.isLoading = false
      })
  },
  initialState: {
    campaigns: {},
    error: null,
    isLoading: false,
  } as CampaignState,
  name: 'Campaigns',
  reducers: {
    clearError(state) {
      state.error = null
    },
  },
})

export const { clearError } = slice.actions

export const fetchCampaigns = createAsyncThunk<Campaign[], void, { rejectValue: string }>(
  'campaigns/fetch-list',
  async (_, thunkApi) => {
    try {
      return (await getCampaignsAPI()) as Campaign[]
    } catch (e) {
      if (e instanceof Error) {
        return thunkApi.rejectWithValue(e.message)
      }
      return thunkApi.rejectWithValue('Unknown error when fetching campaigns')
    }
  },
)

export const fetchCampaign = createAsyncThunk<Campaign, { id: string }, { rejectValue: string }>(
  'campaigns/fetch',
  async ({ id }, thunkApi) => {
    try {
      return (await getCampaignAPI(id)) as Campaign
    } catch (e) {
      if (e instanceof Error) {
        return thunkApi.rejectWithValue(e.message)
      }
      return thunkApi.rejectWithValue('Unknown error when fetching campaigns')
    }
  },
)

export const updateCampaign = createAsyncThunk<
  Campaign,
  { fields: Partial<Campaign> },
  { rejectValue: string }
>('campaigns/update', async ({ fields }, thunkApi) => {
  try {
    return (await updateCampaignAPI(fields)) as Campaign
  } catch (e) {
    if (e instanceof Error) {
      return thunkApi.rejectWithValue(e.message)
    }
    return thunkApi.rejectWithValue('Unknown error when updating campaign')
  }
})

export const createCampaign = createAsyncThunk<
  Campaign,
  { fields: Partial<Campaign> },
  { rejectValue: string }
>('campaigns/create', async ({ fields }, thunkApi) => {
  try {
    return (await createCampaignAPI(fields)) as Campaign
  } catch (e) {
    if (e instanceof Error) {
      return thunkApi.rejectWithValue(e.message)
    }
    return thunkApi.rejectWithValue('Unknown error when creating campaign')
  }
})

export const selectCampaignsState = (state: RootState) => state.campaigns

export const selectAllCampaigns = createSelector(selectCampaignsState, ({ campaigns }) =>
  Object.values(campaigns),
)

export const selectCampaignByIdCreator = (id: string) =>
  createSelector(selectCampaignsState, ({ campaigns }) => campaigns[id])

export default slice.reducer
