import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popover,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd'
import classNames from 'classnames/bind'
import dayjs from 'dayjs'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import PageContainer from '@/components/PageContainer'
import { useCreateCampaign } from '@/hooks/campaigns'

import styles from './index.module.scss'

const cx = classNames.bind(styles)

const CampaignCreate = () => {
  const navigate = useNavigate()
  const createCampaign = useCreateCampaign()
  const [file, setFile] = useState<UploadFile>()
  const onFinish = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (values: any) => {
      createCampaign({
        name: values.name,
        description: values.description,
        target_num_of_runners: values.target_num_of_runners,
      })
    },
    [createCampaign],
  )

  const onFileChange: UploadProps['onChange'] = (info) => {
    const { fileList } = info
    if (fileList.length === 0) {
      setFile(undefined)
    } else {
      setFile(info.file)
    }
  }

  return (
    <PageContainer title='Create Campaign'>
      <div className={cx('container')}>
        <Card className={cx('card')}>
          <div className={cx('header')}>
            <div className={cx('title-row')}>
              <Typography.Title level={2}>New Campaign</Typography.Title>
              <Popover
                content={
                  <div className={cx('header-tooltip')}>
                    Campaigns act as the contract between your advertisement and the drivers.
                    Drivers will be able to see the details listed in the campaign.
                  </div>
                }
              >
                <InfoCircleOutlined className={cx('info-icon')} />
              </Popover>
            </div>
            <Typography.Text>
              Fill out the information below in order to create a campaign. We will review the
              campaign and let you know the next steps.
            </Typography.Text>
          </div>

          <Form
            className={cx('form')}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            layout='horizontal'
            initialValues={{
              name: '',
              description: '',
              startEndDate: [dayjs(), dayjs()],
            }}
            onFinish={onFinish}
          >
            <Form.Item label='Sticker Image' name='image'>
              <Upload listType='picture' maxCount={1} multiple={false} onChange={onFileChange}>
                {file ? null : <Button icon={<UploadOutlined />}>Click to upload</Button>}
              </Upload>
            </Form.Item>
            <Form.Item label='Campaign Name' name='name'>
              <Input name='name' />
            </Form.Item>
            <Form.Item label='Description' name='description'>
              <Input.TextArea autoSize={{ minRows: 3, maxRows: 10 }} />
            </Form.Item>
            <Form.Item label='Number of Drivers' name='target_num_of_runners'>
              <InputNumber />
            </Form.Item>
            <Form.Item label='Duration' name='start_end_date'>
              <DatePicker.RangePicker />
            </Form.Item>

            <Form.Item wrapperCol={{ push: 6 }}>
              <Button htmlType='button' onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <Button type='primary' htmlType='submit' className={cx('submit-button')}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </PageContainer>
  )
}

export default CampaignCreate
