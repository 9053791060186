import dayjs from 'dayjs'

import { Trip } from '@/types'

type TripData = {
  numOfTrips: number
  totalDistance: number
  displayTime: string
}

const DATE_FORMAT = 'DD/MM/YYYY'

export function accumulateTripsByDay(trips: Trip[]) {
  const tripSet: Record<string, TripData> = {}
  Array.from(Array(30).keys())
    .reverse()
    .map((d) => dayjs().subtract(d, 'day').format(DATE_FORMAT))
    .forEach(
      (key) =>
        (tripSet[key] = {
          numOfTrips: 0,
          totalDistance: 0,
          displayTime: key,
        }),
    )

  trips.forEach((t) => {
    const prev = tripSet[dayjs(t.start_time).format(DATE_FORMAT)]
    if (prev) {
      tripSet[dayjs(t.start_time).format(DATE_FORMAT)] = {
        numOfTrips: prev.numOfTrips + 1,
        totalDistance: Number((prev.totalDistance + t.distance).toFixed(1)),
        displayTime: prev.displayTime,
      }
    }
  })
  return tripSet
}

export function accumulateTripsByWeek(trips: Trip[]) {
  const tripSet: Record<string, TripData> = {}
  Array.from(Array(4).keys())
    .reverse()
    .map((d) => dayjs().subtract(d, 'week').week())
    .forEach(
      (key) =>
        (tripSet[key] = {
          numOfTrips: 0,
          totalDistance: 0,
          displayTime: `${key}`,
        }),
    )

  trips.forEach((t) => {
    const prev = tripSet[dayjs(t.start_time).week()]
    if (prev) {
      tripSet[dayjs(t.start_time).week()] = {
        numOfTrips: prev.numOfTrips + 1,
        totalDistance: Number((prev.totalDistance + t.distance).toFixed(1)),
        displayTime: prev.displayTime,
      }
    }
  })
  return tripSet
}

export function convertToHours(timeStr: string): number {
  const parsed = dayjs(timeStr, 'HH:mm:ss', true)

  if (!parsed.isValid()) {
    return 0
  }

  const hours = parsed.hour()
  const minutes = parsed.minute()
  const seconds = parsed.second()
  const milliseconds = parsed.millisecond()

  // Convert everything to hours
  const minutesInHours = minutes / 60
  const secondsInHours = seconds / 3600
  const millisecondsInHours = milliseconds / 3600000

  // Calculate total hours
  const totalHours = hours + minutesInHours + secondsInHours + millisecondsInHours

  return totalHours
}
