import type { MenuProps } from 'antd'
import { Avatar, Menu, Typography } from 'antd'
import Sider from 'antd/es/layout/Sider'
import classNames from 'classnames/bind'
import { useNavigate } from 'react-router-dom'
import useMeasure from 'react-use-measure'

import { ReactComponent as LogoFull } from '@/assets/logo-text-2.svg'
import { ReactComponent as Logo } from '@/assets/logo.svg'
import { useCurrentUser } from '@/hooks/user'

import styles from './CustomSider.module.scss'

type MenuItem = Required<MenuProps>['items'][number]

const cx = classNames.bind(styles)

type Props = {
  menuItems: MenuItem[]
  selectedMenuKey: string
}

const CustomSider = ({ menuItems, selectedMenuKey }: Props) => {
  const navigate = useNavigate()
  const [siderRef, bounds] = useMeasure()
  const user = useCurrentUser()

  return (
    <Sider
      trigger={null}
      width={240}
      collapsible
      style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0 }}
    >
      <div className={cx('sider-container')} ref={siderRef}>
        {bounds.width < 100 ? (
          <Avatar className={cx('avatar')}>{user?.client.name.charAt(0)}</Avatar>
        ) : (
          <Typography.Title level={3} style={{ padding: 16, color: 'white' }}>
            {user?.client.name}
          </Typography.Title>
        )}
        <Menu
          theme='dark'
          mode='inline'
          items={menuItems}
          onClick={(item) => navigate(item.key)}
          selectedKeys={[selectedMenuKey]}
        />
        <div className={cx('footer')}>
          {bounds.width < 100 ? (
            <Logo className={cx('logo')} />
          ) : (
            <LogoFull className={cx('logo-full')} />
          )}
        </div>
      </div>
    </Sider>
  )
}

export default CustomSider
