import { Avatar, List } from 'antd'

type ListItem = {
  title: string
  description: string
}

const LIST_ITEMS: ListItem[] = [
  {
    title: 'Create New Campaigns',
    description:
      'Get started by creating a campaign, and we will get in touch with the details of your campaign',
  },
  {
    title: 'Customize Your Advertisement Target',
    description: 'Get your customized advertisement plan and pricing most suited to your business',
  },
  {
    title: 'View Your Campaign Insights',
    description: 'You can see the approximated metrics for the impact of your advertisement',
  },
  {
    title: 'Stay Connected',
    description: 'Stay connected with us and to help your advertisement grow',
  },
]

const TipsAndTricks = () => {
  return (
    <List
      itemLayout='horizontal'
      dataSource={LIST_ITEMS}
      style={{ marginTop: '-15px' }}
      renderItem={(item, index) => (
        <List.Item>
          <List.Item.Meta
            title={item.title}
            description={item.description}
            avatar={<Avatar style={{ backgroundColor: '#7265e6' }}>{index + 1}</Avatar>}
          />
        </List.Item>
      )}
    />
  )
}

export default TipsAndTricks
