import HeatMapView from '@/components/HeatMapView'
import { useEnsureTripsForUser, useTripsForUser } from '@/hooks/trips'

type Props = {
  uid: string
}

const UserLocations = ({ uid }: Props) => {
  useEnsureTripsForUser(uid)
  const trips = useTripsForUser(uid)

  return <HeatMapView trips={trips} />
}

export default UserLocations
