import { Button, Result, Table, TableColumnsType } from 'antd'
import { TableRowSelection } from 'antd/es/table/interface'
import classNames from 'classnames/bind'
import dayjs from 'dayjs'
import { Key, useCallback, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useCampaigns } from '@/hooks/campaigns'
import { useCurrentUser } from '@/hooks/user'

import styles from './CampaignList.module.scss'

const cx = classNames.bind(styles)

type DataType = {
  key: string
  name: string
  period: string
}

const getColumns: (isArund: boolean) => TableColumnsType<DataType> = (isArund: boolean) =>
  [
    {
      title: 'Name',
      dataIndex: 'name',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (text: any, item: any) => <Link to={item.key}>{text}</Link>,
    },
    {
      title: 'Period',
      dataIndex: 'period',
    },
    isArund
      ? {
          title: 'Client',
          dataIndex: 'company',
        }
      : null,
  ].filter(Boolean) as TableColumnsType<DataType>

const CampaignList = () => {
  const navigate = useNavigate()
  const user = useCurrentUser()
  const { campaigns, error } = useCampaigns()
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])

  const onSelectChange = useCallback((newKeys: Key[]) => setSelectedRowKeys(newKeys), [])

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  }

  const data: DataType[] = useMemo(
    () =>
      campaigns.map((c) => ({
        name: c.name,
        key: c.id,
        period: `${dayjs(c.start_date).format('MMM D, YYYY')} - ${dayjs(c.end_date).format(
          'MMM D, YYYY',
        )}`,
        company: c.company,
      })),
    [campaigns],
  )

  return (
    <div className={cx('container')}>
      <div className={cx('table-header-row')}>
        <Button
          className={cx('table-create-button')}
          type='primary'
          onClick={() => navigate('/campaigns/new')}
        >
          New Campaign
        </Button>
      </div>
      {error ? (
        <Result status={500} title='Something went wrong' subTitle='Please try again later' />
      ) : (
        <Table
          rowSelection={rowSelection}
          columns={getColumns(user?.is_arund_admin || false)}
          dataSource={data}
        />
      )}
    </div>
  )
}

export default CampaignList
