import { Card, Tabs, TabsProps } from 'antd'
import classNames from 'classnames/bind'
import { useMemo } from 'react'
import { useMatch } from 'react-router-dom'

import PageContainer from '@/components/PageContainer'
import { useAppUserById } from '@/hooks/app-user'

import styles from './index.module.scss'
import UserDetails from './UserDetails'
import UserLocations from './UserLocations'

const cx = classNames.bind(styles)

const AppUserDetail = () => {
  const match = useMatch('/users/:uid')
  const uid = match?.params.uid || ''
  const user = useAppUserById(uid)

  const items: TabsProps['items'] = useMemo(() => {
    return [
      {
        key: 'details',
        label: 'Details',
        children: <UserDetails user={user} />,
      },
      {
        key: 'locations',
        label: 'Locations',
        children: <UserLocations uid={uid} />,
      },
    ]
  }, [uid, user])

  if (!user) {
    return null
  }

  return (
    <PageContainer title='User Detail'>
      <Card className={cx('main-card')}>
        <Tabs items={items} />
      </Card>
    </PageContainer>
  )
}

export default AppUserDetail
