import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import {
  createCampaignPoiAPI,
  deleteCampaignPOIAPI,
  getCampaignPOIsAPIByCampaign,
  updateCampaignPoiAPI,
} from '@/api/campaign-poi'
import { CampaignPoi } from '@/types'

import { RootState } from '.'

type CampaignPoiState = {
  error: string | null
  isLoading: boolean
  campaigns: {
    [campaignId: string]: CampaignPoi[]
  }
}

const slice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(fetchCampaignPois.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCampaignPois.fulfilled, (state, action) => {
        state.error = null
        state.isLoading = false
        state.campaigns[action.meta.arg.id] = action.payload
      })
      .addCase(fetchCampaignPois.rejected, (state, action) => {
        state.error = action.payload || ''
        state.isLoading = false
      })
  },
  initialState: {
    campaigns: {},
    error: null,
    isLoading: false,
  } as CampaignPoiState,
  name: 'CampaignPOIs',
  reducers: {
    clearError(state) {
      state.error = null
    },
  },
})

export const { clearError } = slice.actions

export const fetchCampaignPois = createAsyncThunk<
  CampaignPoi[],
  { id: string },
  { rejectValue: string }
>('campaigns/poi/fetch-list', async ({ id }, thunkApi) => {
  try {
    return (await getCampaignPOIsAPIByCampaign(id)) as CampaignPoi[]
  } catch (e) {
    if (e instanceof Error) {
      return thunkApi.rejectWithValue(e.message)
    }
    return thunkApi.rejectWithValue('Unknown error when fetching campaign POIs')
  }
})

export const updateCampaignPoi = createAsyncThunk<
  CampaignPoi,
  { fields: Partial<CampaignPoi> },
  { rejectValue: string }
>('campaigns/poi/update', async ({ fields }, thunkApi) => {
  try {
    return (await updateCampaignPoiAPI(fields)) as CampaignPoi
  } catch (e) {
    if (e instanceof Error) {
      return thunkApi.rejectWithValue(e.message)
    }
    return thunkApi.rejectWithValue('Unknown error when updating campaign poi')
  }
})

export const createCampaignPoi = createAsyncThunk<
  CampaignPoi,
  { fields: Partial<CampaignPoi> },
  { rejectValue: string }
>('campaigns/poi/create', async ({ fields }, thunkApi) => {
  try {
    return (await createCampaignPoiAPI(fields)) as CampaignPoi
  } catch (e) {
    if (e instanceof Error) {
      return thunkApi.rejectWithValue(e.message)
    }
    return thunkApi.rejectWithValue('Unknown error when creating campaign poi')
  }
})

export const deleteCampaignPoi = createAsyncThunk<void, { id: string }, { rejectValue: string }>(
  'campaigns/poi/delete',
  async ({ id }, thunkApi) => {
    try {
      return await deleteCampaignPOIAPI(id)
    } catch (e) {
      if (e instanceof Error) {
        return thunkApi.rejectWithValue(e.message)
      }
      return thunkApi.rejectWithValue('Unknown error when deleting campaign poi')
    }
  },
)

export const selectCampaignPOIState = (state: RootState) => state.campaignPOIs

export const selectCampaignPOIs = (campaignId: string) =>
  createSelector(selectCampaignPOIState, (state) => state.campaigns[campaignId] || [])

export default slice.reducer
