import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyAfdq9MlZfJyVuYSYAzwdIDa-Rmr1kfkqY',
  authDomain: 'arundclient.firebaseapp.com',
  projectId: 'arundclient',
  storageBucket: 'arundclient.appspot.com',
  messagingSenderId: '870811230935',
  appId: '1:870811230935:web:6c2ec20d41172bda858692',
  measurementId: 'G-FBB1XJVKRX',
}

const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)

export default app
