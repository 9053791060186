import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { getAppUserAPI } from '@/api'
import { AppUser } from '@/types'

import { RootState } from '.'

type AppUserStorage = { [uid: string]: AppUser }

type AppUserState = {
  error: string | null
  isLoading: boolean
  users: AppUserStorage
}

const slice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(fetchAppUsers.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchAppUsers.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.users = action.payload.reduce((acc, cur) => {
          acc[cur.uid] = cur
          return acc
        }, {} as AppUserStorage)
      })
      .addCase(fetchAppUsers.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload || ''
      })
  },
  initialState: {
    error: null,
    isLoading: true,
    users: {},
  } as AppUserState,
  name: 'User',
  reducers: {},
})

export const fetchAppUsers = createAsyncThunk<AppUser[], undefined, { rejectValue: string }>(
  'app-user/fetch',
  async (_, thunkApi) => {
    try {
      return (await getAppUserAPI()) as AppUser[]
    } catch (e) {
      if (e instanceof Error) {
        return thunkApi.rejectWithValue(e.message)
      }
      return thunkApi.rejectWithValue('Unknown error when fetching app users')
    }
  },
)

export const selectAppUsersState = (state: RootState) => state.appUsers

export const selectAllAppUsers = createSelector(selectAppUsersState, ({ users }) =>
  Object.values(users),
)

export const selectAppUserByIdCreator = (uid: string) =>
  createSelector(selectAppUsersState, ({ users }) => users[uid])

export default slice.reducer
