import { useCallback, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@/store'
import {
  createCampaignPoi,
  deleteCampaignPoi,
  fetchCampaignPois,
  selectCampaignPOIs,
  selectCampaignPOIState,
  updateCampaignPoi,
} from '@/store/campaign-poi'
import { CampaignPoi } from '@/types'

export const useEnsureCampaignPOIs = (id: string) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (id) {
      dispatch(fetchCampaignPois({ id }))
    }
  }, [dispatch, id])
}

export const useCampaignPoisById = (id: string) => {
  const { error, isLoading } = useAppSelector(selectCampaignPOIState)
  const campaignPois = useAppSelector(selectCampaignPOIs(id))
  return { campaignPois, error, isLoading }
}

export const useDeleteCampaignPoiById = () => {
  const dispatch = useAppDispatch()
  const deletePoi = useCallback(
    (id: string) => {
      dispatch(deleteCampaignPoi({ id }))
    },
    [dispatch],
  )
  return deletePoi
}

export const useCreateCampaignPoi = () => {
  const dispatch = useAppDispatch()

  const createNewCampaignPoi = useCallback(
    (fields: Partial<CampaignPoi>) => dispatch(createCampaignPoi({ fields })),
    [dispatch],
  )

  return createNewCampaignPoi
}

export const useUpdateCampaignPoi = () => {
  const dispatch = useAppDispatch()

  const updateNewCampaignPoi = useCallback(
    (fields: Partial<CampaignPoi>) => dispatch(updateCampaignPoi({ fields })),
    [dispatch],
  )

  return updateNewCampaignPoi
}
