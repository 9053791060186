import { ControlOutlined } from '@ant-design/icons'
import {
  CircleF,
  GoogleMap,
  HeatmapLayerF,
  InfoWindowF,
  MarkerF,
  useJsApiLoader,
} from '@react-google-maps/api'
import { Button, Card, FloatButton, InputNumber, Slider, Typography } from 'antd'
import classNames from 'classnames/bind'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'

import { useCampaignPoisById, useEnsureCampaignPOIs } from '@/hooks/campaign-poi'
import { Trip } from '@/types'

import styles from './index.module.scss'

const cx = classNames.bind(styles)

type Props = {
  trips: Trip[]
  campaignId?: string
}

type Point = {
  name: string
  id: string
  lat: number
  lng: number
  radius: number
  address: string
}

const MAP_CONFIG = {
  id: 'map',
  googleMapsApiKey: 'AIzaSyCwmaWNGQrqfgNcelRzeezmTcZ9fGn6bwk',
  libraries: ['visualization'] as 'visualization'[],
}

const CENTER = {
  lat: 43.6532,
  lng: -79.3832,
}

const ZOOM = 12

const HEATMAP_CONFIG = {
  dissipating: true,
  radius: 20,
  maxIntensity: 20,
}

const CIRCLE_CONFIG = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.7,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.1,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 2000,
  zIndex: 1,
}

const HeatMapView = ({ trips, campaignId }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = useState<google.maps.Map>()
  const { isLoaded } = useJsApiLoader(MAP_CONFIG)
  const [radius, setRadius] = useState(HEATMAP_CONFIG.radius)
  const [maxIntensity, setMaxIntensity] = useState(HEATMAP_CONFIG.maxIntensity)
  const [showControl, setShowControl] = useState(false)
  const [activeMarker, setActiveMarker] = useState('')

  const [poiList, setPoiList] = useState<Point[]>([])

  useEnsureCampaignPOIs(campaignId || '')

  const { campaignPois } = useCampaignPoisById(campaignId || '')

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map)
  }, [])

  const handleMarkerHover = (id: string) => {
    setActiveMarker(id)
  }

  const handleMarkerOut = () => {
    setActiveMarker('')
  }

  const [visibleCircles, setVisibleCircles] = useState<Set<string>>(new Set())

  const toggleCircleVisibility = (id: string) => {
    setVisibleCircles((prevVisibleCircles) => {
      const newVisibleCircles = new Set(prevVisibleCircles)
      if (newVisibleCircles.has(id)) {
        newVisibleCircles.delete(id)
      } else {
        newVisibleCircles.add(id)
      }
      return newVisibleCircles
    })
  }

  const points = useMemo(() => {
    // This check is to solve a bug where `google.maps` is undefined when module is not loaded
    if (isLoaded) {
      return trips
        .map((t) => t.safe_coordinates)
        .flatMap((c) => c)
        .map((p) => new google.maps.LatLng(p[0], p[1]))
    }
    return []
    // Avoid re-calculation unless the number of trips changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trips.length, isLoaded])

  const radiusProps = useMemo(
    () => ({
      value: radius,
      onChange: (v: number | null) => setRadius(v ?? HEATMAP_CONFIG.radius),
      min: 0,
      max: 100,
    }),
    [radius],
  )

  const maxIntensityProps = useMemo(
    () => ({
      value: maxIntensity,
      onChange: (v: number | null) => setMaxIntensity(v ?? HEATMAP_CONFIG.maxIntensity),
      min: 0,
      max: 100,
    }),
    [maxIntensity],
  )

  useEffect(() => {
    const newPoiList: Point[] = []
    campaignPois.forEach((p) => {
      const newPoint: Point = {
        name: p.name,
        id: p.id,
        lat: p.coordinate[0],
        lng: p.coordinate[1],
        address: p.address,
        radius: p.radius * 1000,
      }
      newPoiList.push(newPoint)
    })
    setPoiList([...newPoiList])
  }, [campaignPois])

  return isLoaded ? (
    <div className={cx('container')}>
      <GoogleMap
        mapContainerClassName={cx('container')}
        onLoad={onLoad}
        center={CENTER}
        zoom={ZOOM}
      >
        <HeatmapLayerF options={{ radius, maxIntensity, data: points }} data={points} />
        {poiList.map((poi, index) => (
          <Fragment key={poi.id}>
            <CircleF
              center={poi}
              options={CIRCLE_CONFIG}
              visible={visibleCircles.has(poi.id)}
              radius={poi.radius}
            />
            <MarkerF
              key={index}
              position={poi}
              animation={window.google.maps.Animation.DROP}
              onClick={() => toggleCircleVisibility(poi.id)}
              onMouseOver={() => handleMarkerHover(poi.id)}
              onMouseOut={handleMarkerOut}
            >
              {activeMarker === poi.id && poi.name && (
                <InfoWindowF onCloseClick={() => setActiveMarker('')}>
                  <>
                    <h3>{poi.name}</h3>
                    <h4>{poi.address}</h4>
                  </>
                </InfoWindowF>
              )}
            </MarkerF>
          </Fragment>
        ))}
      </GoogleMap>
      {showControl ? (
        <Card className={cx('control')}>
          <div className={cx('control-inner')}>
            <Typography.Text style={{ alignSelf: 'center' }}>Radius</Typography.Text>
            <Slider {...radiusProps} />
            <InputNumber {...radiusProps} />

            <Typography.Text style={{ alignSelf: 'center' }}>Max Intensity</Typography.Text>
            <Slider {...maxIntensityProps} />
            <InputNumber {...maxIntensityProps} />
          </div>
          <Button onClick={() => setShowControl(false)}>Hide</Button>
        </Card>
      ) : (
        <FloatButton
          className={cx('float-control')}
          icon={<ControlOutlined />}
          onClick={() => {
            setShowControl(true)
          }}
        />
      )}
    </div>
  ) : null
}

export default HeatMapView
