import HeatMapView from '@/components/HeatMapView'
import PageContainer from '@/components/PageContainer'
import { useAllTrips, useEnsureAllTrips } from '@/hooks/trips'

const AllUsersMap = () => {
  useEnsureAllTrips()
  const trips = useAllTrips()

  return (
    <PageContainer>
      <HeatMapView trips={trips} />
    </PageContainer>
  )
}

export default AllUsersMap
