export interface Point {
  latitude: number
  longitude: number
}

function distanceBetweenPoints(point1: Point, point2: Point): number {
  const earthRadiusKm = 6371

  const lat1Radians = toRadians(point1.latitude)
  const lat2Radians = toRadians(point2.latitude)
  const deltaLat = toRadians(point2.latitude - point1.latitude)
  const deltaLng = toRadians(point2.longitude - point1.longitude)

  //Havershine formula
  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1Radians) * Math.cos(lat2Radians) * Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return earthRadiusKm * c
}

function toRadians(degrees: number): number {
  return degrees * (Math.PI / 180)
}

export function isWithinRadius(point: Point, poi: Point, radiusKm: number): boolean {
  const distance = distanceBetweenPoints(point, poi)
  return distance <= radiusKm
}
