import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@/store'
import {
  fetchAllTrips,
  fetchTripsForCampaign,
  fetchTripsForUser,
  selectTripsForCampaignCreator,
  selectTripsForUserCreator,
} from '@/store/trips'

export const useEnsureTripsForCampaign = (campaignId: string) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchTripsForCampaign({ campaignId }))
  }, [campaignId, dispatch])
}

export const useTripsForCampaign = (campaignId: string) => {
  return useAppSelector(selectTripsForCampaignCreator(campaignId))
}

export const useEnsureTripsForUser = (uid: string) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchTripsForUser({ uid }))
  }, [dispatch, uid])
}

export const useTripsForUser = (uid: string) => {
  return useAppSelector(selectTripsForUserCreator(uid))
}

export const useEnsureAllTrips = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchAllTrips())
  }, [dispatch])
}

export const useAllTrips = () => {
  return useAppSelector((state) => state.trips.allTrips)
}
