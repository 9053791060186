import { Segmented } from 'antd'
import classNames from 'classnames/bind'
import { useMemo, useState } from 'react'
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { Trip } from '@/types'
import { convertToHours } from '@/utils/trips'

import styles from './index.module.scss'

const cx = classNames.bind(styles)

type Props = {
  chartMinHeight?: number
  chartMinWidth?: number
  chartMinRadius?: number
  trips: Trip[]
  campaignAreas: string[]
}

const COLORS = ['#82ca9d', '#8884d8', '#302d6e', '#a151c9', '#c95191']

const DEFAULT_DATA = [{ name: 'No Data', value: 0 }]

const TimeSpentChart = ({
  chartMinHeight = 300,
  chartMinWidth = 200,
  chartMinRadius = 120,
  trips,
  campaignAreas,
}: Props) => {
  const elementExists = (name: string, map: Map<string, boolean>): boolean => {
    // Convert the name to lowercase and split it into an array of elements matches the following connectors
    // Hyphen (-), Whitespace (\s), Dash (-), Period (.)
    const element = name.toLowerCase().split(/[-\s\u2014\u2013.]/)

    // It is used to match any of the elements if they appear at the start, end,
    // or surrounded by any of the characters used in the split (Hyphen (-), Whitespace (\s), Dash (-), Period (.))
    const regex = new RegExp(
      `(^|[-\\s\u2014\u2013.])(${element.join('|')})($|[-\\s\u2014\u2013.])`,
      'i',
    )
    for (const key of map.keys()) {
      if (regex.test(key)) {
        return true
      }
    }
    return false
  }

  const [selectedChartType, setSelectedChartType] = useState('Shown As Bar')
  const campaignHoursData = useMemo(() => {
    const hoursMap = new Map<string, number>()
    trips.forEach((trip) => {
      if (trip.region && !isNaN(convertToHours(trip.duration))) {
        // Calculate existing hours for the area or start with 0
        const existingHours = hoursMap.get(trip.region) || 0
        // Add current trip's duration to the existing hours
        hoursMap.set(
          trip.region,
          Number((existingHours + convertToHours(trip.duration)).toFixed(1)),
        )
      }
    })

    // Convert the map to an array format
    const data: { name: string; value: number }[] = Array.from(hoursMap, ([name, value]) => ({
      name,
      value,
    }))
    data.sort((a, b) => b.value - a.value)
    return data.length > 0 ? data : DEFAULT_DATA
  }, [trips])

  const campaignAreasMap: Map<string, boolean> = useMemo(
    () => new Map(campaignAreas.map((item) => [item.toLowerCase(), true])),
    [campaignAreas],
  )

  const campaignHoursInTargetData = useMemo(() => {
    const data: { name: string; value: number }[] = []
    let totalHours = 0
    let targetHours = 0
    trips.forEach((trip) => {
      if (!isNaN(convertToHours(trip.duration))) {
        const tripHours = Number(convertToHours(trip.duration).toFixed(1))
        totalHours += tripHours
        if (trip.region !== null && elementExists(trip.region.toLowerCase(), campaignAreasMap)) {
          targetHours += tripHours
        }
      }
    })

    data.push(
      { name: 'Hours In Target Area', value: targetHours },
      {
        name: 'Hours Not In Target Area',
        value: totalHours - targetHours,
      },
    )

    return data.length > 0 ? data : DEFAULT_DATA
  }, [trips, campaignAreasMap])

  return (
    <div className={cx('container')}>
      <Segmented
        className={cx('segmented')}
        options={['Shown As Bar', 'Shown As Pie']}
        value={selectedChartType}
        onChange={(v) => setSelectedChartType(v as string)}
      />
      <div className={cx('chart-container')}>
        {selectedChartType === 'Shown As Bar' ? (
          <ResponsiveContainer
            minHeight={chartMinHeight}
            maxHeight={2 * chartMinHeight}
            width='100%'
            aspect={2}
          >
            <BarChart
              data={campaignHoursData}
              margin={{ bottom: 50, left: 50, right: 20, top: 20 }}
            >
              <XAxis
                dataKey='name'
                interval={0}
                tick={{ fontSize: 12 }}
                angle={-35}
                textAnchor='end'
                height={90} // Increase the height of the x-axis to accommodate rotated labels
              />
              <YAxis
                domain={[0, (dataMax: number) => Math.ceil(dataMax * 1.1)]}
                tickFormatter={(value) => `${value} hours`}
                width={80}
              />
              <Legend verticalAlign='top' height={36} />
              <Tooltip />
              <Bar
                fill='#82ca9d'
                dataKey='value'
                name='Hours Spent'
                label={{ position: 'top', fontSize: '110%' }}
              />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <div className={cx('pie-charts-container')}>
            <ResponsiveContainer minHeight={chartMinHeight}>
              <PieChart width={chartMinWidth} height={chartMinHeight}>
                <Pie
                  cx='45%'
                  data={campaignHoursInTargetData}
                  labelLine={false}
                  outerRadius={chartMinRadius}
                  fill='#8884d8'
                  dataKey='value'
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {campaignHoursInTargetData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    </div>
  )
}

export default TimeSpentChart
