import { Calendar, Descriptions, Popover, Typography } from 'antd'
import classNames from 'classnames/bind'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useMemo } from 'react'

import { Trip } from '@/types'

import styles from './index.module.scss'

const cx = classNames.bind(styles)

const DATE_FORMAT = 'DD/MM/YYYY'

type Props = {
  trips: Trip[]
  startDate: string
  endDate: string
}

type TripData = {
  numOfTrips: number
  totalDistance: number
}

const TripCalendar = ({ trips, startDate, endDate }: Props) => {
  const tripsByDate = useMemo(() => {
    return trips.reduce((acc, cur) => {
      const prev = acc[dayjs(cur.start_time).format(DATE_FORMAT)] || {
        numOfTrips: 0,
        totalDistance: 0,
      }
      acc[dayjs(cur.start_time).format(DATE_FORMAT)] = {
        numOfTrips: prev.numOfTrips + 1,
        totalDistance: prev.totalDistance + cur.distance,
      }
      return acc
    }, {} as { [date: string]: TripData })
  }, [trips])

  const popoverRender = useCallback((data: TripData) => {
    return (
      <Descriptions className={cx('popover')} layout='vertical' column={2}>
        <Descriptions.Item label='Trips recorded'>{data.numOfTrips}</Descriptions.Item>
        <Descriptions.Item label='Distance traveled'>{`${data.totalDistance} KM`}</Descriptions.Item>
      </Descriptions>
    )
  }, [])

  const dateCellRender = useCallback(
    (value: Dayjs) => {
      const tripData = tripsByDate[value.format(DATE_FORMAT)]

      if (value.isSame(startDate, 'date')) {
        return <div>Campaign start</div>
      } else if (value.isSame(endDate, 'date')) {
        return <div>Campaign finish</div>
      } else {
        return tripData ? (
          <Popover content={popoverRender(tripData)}>
            <div className={cx('cell')}>
              <div className={cx('display-row')}>
                <Typography.Text className={cx('main-text')}>{tripData.numOfTrips}</Typography.Text>
                <Typography.Text type='secondary'>trips</Typography.Text>
              </div>
              <div className={cx('display-row')}>
                <Typography.Text className={cx('main-text')}>
                  {tripData.totalDistance.toFixed(2)}
                </Typography.Text>
                <Typography.Text type='secondary'>KM</Typography.Text>
              </div>
            </div>
          </Popover>
        ) : null
      }
    },
    [endDate, popoverRender, startDate, tripsByDate],
  )

  return <Calendar cellRender={dateCellRender} />
}

export default TripCalendar
