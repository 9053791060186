import { Typography } from 'antd'
import classNames from 'classnames/bind'

import MileageInTargetChart from '@/components/MileageInTargetChart/Index'
import TimeInTargetChart from '@/components/TimeInTargetChart/Index'
import TimeSpentChart from '@/components/TimeSpentChart'
import TripCalendar from '@/components/TripCalendar'
import TripChart from '@/components/TripChart'
import { Campaign, CampaignPoi, Trip } from '@/types'

import styles from './CampaignAnalytics.module.scss'

const cx = classNames.bind(styles)

type Props = {
  campaign: Campaign
  trips: Trip[]
  campaignPois: CampaignPoi[]
  isAdmin: boolean | undefined
}

const CampaignAnalytics = ({ campaign, trips, campaignPois }: Props) => {
  return (
    <div className={cx('container')}>
      <Typography.Title level={2} className={cx('title')}>
        Your Campaign Mileage Spent inside Targeted Areas
      </Typography.Title>
      <MileageInTargetChart trips={trips} campaignPois={campaignPois} />

      <Typography.Title level={2} className={cx('title')}>
        Your Campaign Hours Spent inside Targeted Areas
      </Typography.Title>
      <TimeInTargetChart trips={trips} campaignPois={campaignPois} />

      <Typography.Title level={2} className={cx('title')}>
        Your Campaign Hours Spent Across Areas
      </Typography.Title>
      <TimeSpentChart trips={trips} campaignAreas={campaign.target_areas} />

      <Typography.Title level={2} className={cx('title')}>
        Trip Trends
      </Typography.Title>
      <TripChart trips={trips} />

      <Typography.Title level={2} className={cx('title')}>
        Campaign Timeline
      </Typography.Title>
      <TripCalendar trips={trips} startDate={campaign.start_date} endDate={campaign.end_date} />
    </div>
  )
}

export default CampaignAnalytics
