import { DatePicker, Form, Input } from 'antd'
import classNames from 'classnames/bind'
import dayjs from 'dayjs'

import CampaignStatus from '@/components/CampaignStatus'
import { Campaign } from '@/types'

import styles from './CampaignDescription.module.scss'

const cx = classNames.bind(styles)

type Props = {
  campaign: Campaign
}

const CampaignDescription = ({ campaign }: Props) => {
  return (
    <div className={cx('container')}>
      <div className={cx('form')}>
        <CampaignStatus campaign={campaign} />
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} layout='horizontal' disabled>
          <Form.Item label='Campaign Name'>
            <Input value={campaign.name} />
          </Form.Item>
          <Form.Item label='Description'>
            <Input.TextArea value={campaign.description} autoSize={{ minRows: 3, maxRows: 10 }} />
          </Form.Item>
          <Form.Item label='Duration'>
            <Input value={campaign.duration} />
          </Form.Item>
          <Form.Item label='Minimum Distance'>
            <Input value={campaign.minimum_distance} />
          </Form.Item>
          <Form.Item label='Number of Runners'>
            <Input value={campaign.num_of_runners} />
          </Form.Item>
          <Form.Item label='Start and End Date'>
            <DatePicker.RangePicker
              value={[dayjs(campaign.start_date), dayjs(campaign.end_date)]}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default CampaignDescription
