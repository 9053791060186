import { Segmented } from 'antd'
import classNames from 'classnames/bind'
import React, { useMemo, useState } from 'react'
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { Trip } from '@/types'
import { accumulateTripsByDay, accumulateTripsByWeek } from '@/utils/trips'

import styles from './index.module.scss'

const cx = classNames.bind(styles)

type Props = {
  trips: Trip[]
  chartMinHeight?: number
}

const TripChart = ({ trips, chartMinHeight = 300 }: Props) => {
  const [selectedGroupBy, setSelectedGroupBy] = useState('Group by week')

  const tripsByDay = useMemo(() => {
    return accumulateTripsByDay(trips)
  }, [trips])

  const tripsByWeek = useMemo(() => {
    return accumulateTripsByWeek(trips)
  }, [trips])

  const data = useMemo(() => {
    const groupedTrips = selectedGroupBy === 'Group by week' ? tripsByWeek : tripsByDay
    return Object.values(groupedTrips)
  }, [selectedGroupBy, tripsByDay, tripsByWeek])

  return (
    <div className={cx('container')}>
      <Segmented
        className={cx('segmented')}
        options={['Group by week', 'Group by day']}
        value={selectedGroupBy}
        onChange={(v) => setSelectedGroupBy(v as string)}
      />
      <div className={cx('chart-container')}>
        <ResponsiveContainer minHeight={chartMinHeight}>
          <BarChart data={data}>
            <XAxis dataKey='displayTime' />
            <YAxis
              domain={[0, (dataMax: number) => Math.ceil(dataMax * 1.1)]}
              tickFormatter={(value) => `${value} km`}
              width={100}
            />
            <Legend />
            <Tooltip />
            <Bar
              fill='#8884d8'
              dataKey='numOfTrips'
              name='Number of Trips'
              label={{ position: 'top', fontSize: '110%' }}
            />
            <Bar
              fill='#82ca9d'
              dataKey='totalDistance'
              name='Distance Traveled'
              label={{ position: 'top', fontSize: '110%' }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default TripChart
