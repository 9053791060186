import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import appUserReducer from './app-user'
import campaignPoiReducer from './campaign-poi'
import campaignReducer from './campaigns'
import tripReducer from './trips'
import userReducer from './user'

export const store = configureStore({
  reducer: {
    user: userReducer,
    campaigns: campaignReducer,
    campaignPOIs: campaignPoiReducer,
    appUsers: appUserReducer,
    trips: tripReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
