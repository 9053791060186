import { Result, Table, TableColumnsType } from 'antd'
import { TableRowSelection } from 'antd/es/table/interface'
import { Key, useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { useAppUsers } from '@/hooks/app-user'
import { AppUser } from '@/types'
import { getUserName } from '@/utils/app-user'

type DataType = {
  key: string
  name: string
}

type UserViewModel = {
  name: string
  key: string
  email: string
  city: string
}

class UserViewModelComparator {
  // assumes 'pattern' is already in lower case

  public static matchesName(viewModel: UserViewModel, pattern: string) {
    if (viewModel?.name == null || pattern == null) return false

    return viewModel.name.toLowerCase().includes(pattern)
  }

  public static matchesEmail(viewModel: UserViewModel, pattern: string) {
    if (viewModel?.name == null || pattern == null) return false

    return viewModel.email.toLowerCase().includes(pattern)
  }

  public static matchesCity(viewModel: UserViewModel, pattern: string) {
    if (viewModel?.name == null || pattern == null) return false

    return viewModel.city.toLowerCase().includes(pattern)
  }
}

type Props = {
  filter?: string
  onUserClicked?: (uid: string) => void
}

const columns: TableColumnsType<DataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text, item) => <Link to={item.key}>{text}</Link>,
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'City',
    dataIndex: 'city',
  },
]

const UsersList = ({ filter, onUserClicked }: Props) => {
  const { users, error } = useAppUsers()
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [pageNumber, setPageNumber] = useState(1)

  const onSelectChange = useCallback((newKeys: Key[]) => setSelectedRowKeys(newKeys), [])

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  }

  const createUserViewModels = (users: AppUser[], filter?: string) => {
    let viewModels: UserViewModel[] = users.map((u) => ({
      name: getUserName(u),
      key: u.uid,
      email: u.email,
      city: u.city,
    }))

    if (filter) {
      viewModels = viewModels.filter(
        (vm) =>
          UserViewModelComparator.matchesName(vm, filter) ||
          UserViewModelComparator.matchesEmail(vm, filter) ||
          UserViewModelComparator.matchesCity(vm, filter),
      )
    }

    setPageNumber(1)
    return viewModels
  }

  const data: DataType[] = useMemo(() => createUserViewModels(users, filter), [users, filter])

  if (error) {
    return <Result status={500} title='Something went wrong' subTitle='Please try again later' />
  }

  return (
    <Table
      rowSelection={rowSelection}
      columns={columns}
      dataSource={data}
      onRow={(record) => {
        return { onClick: () => onUserClicked?.(record.key) }
      }}
      pagination={{ current: pageNumber, onChange: (page) => setPageNumber(page) }}
    />
  )
}

export default UsersList
