import { ClientUser } from '@/types'

import { fetchAPI } from './base'

export const getUserAPI = (uid: string) => {
  return fetchAPI(`client_users/${uid}`, {
    method: 'GET',
  })
}

export const createUserAPI = (fields: Partial<ClientUser>) => {
  const body = JSON.stringify(fields)
  return fetchAPI('users/signup', {
    body,
    method: 'POST',
  })
}
