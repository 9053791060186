import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@/store'
import {
  fetchAppUsers,
  selectAllAppUsers,
  selectAppUserByIdCreator,
  selectAppUsersState,
} from '@/store/app-user'

export const useEnsureAppUsers = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchAppUsers())
  }, [dispatch])
}

export const useAppUsers = () => {
  const { isLoading, error } = useAppSelector(selectAppUsersState)
  const users = useAppSelector(selectAllAppUsers)

  return { users, isLoading, error }
}

export const useAppUserById = (uid: string) => {
  return useAppSelector(selectAppUserByIdCreator(uid))
}
