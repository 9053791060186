import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { createUserAPI, getUserAPI } from '@/api'
import { ClientUser } from '@/types'

import { RootState } from '.'

type UserState = {
  error: string | null
  isLoading: boolean
  isUploading: boolean
  user: ClientUser | null
}

const slice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.user = action.payload
        state.error = null
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload || ''
      })
  },
  initialState: {
    error: null,
    isLoading: true,
    isUploading: false,
    user: null,
  } as UserState,
  name: 'User',
  reducers: {},
})

export const fetchUser = createAsyncThunk<ClientUser, { uid: string }, { rejectValue: string }>(
  'user/fetch',
  async ({ uid }, thunkApi) => {
    try {
      return (await getUserAPI(uid)) as ClientUser
    } catch (e) {
      if (e instanceof Error) {
        return thunkApi.rejectWithValue(e.message)
      }
      return thunkApi.rejectWithValue('Unknown error when fetching user')
    }
  },
)

export const createUser = createAsyncThunk<
  Partial<ClientUser>,
  { fields: Partial<ClientUser> },
  { rejectValue: string }
>('user/create', async ({ fields }, thunkApi) => {
  try {
    return (await createUserAPI(fields)) as Partial<ClientUser>
  } catch (e) {
    if (e instanceof Error) {
      return thunkApi.rejectWithValue(e.message)
    }
    return thunkApi.rejectWithValue('Unknown error when creating user')
  }
})

export const selectUser = (state: RootState) => state.user

export default slice.reducer
