import { Segmented } from 'antd'
import classNames from 'classnames/bind'
import { useMemo, useState } from 'react'
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { CampaignPoi, Trip } from '@/types'
import { isWithinRadius, Point } from '@/utils/poi'

import styles from './index.module.scss'

const cx = classNames.bind(styles)

type Props = {
  chartMinHeight?: number
  chartMinWidth?: number
  chartMinRadius?: number
  trips: Trip[]
  campaignPois: CampaignPoi[]
}

const COLORS = ['#82ca9d', '#8884d8', '#302d6e', '#a151c9', '#c95191']

const DEFAULT_DATA = [{ name: 'No Data', value: 0 }]

const MileageInTargetChart = ({
  chartMinHeight = 300,
  chartMinWidth = 200,
  chartMinRadius = 120,
  trips,
  campaignPois,
}: Props) => {
  const [selectedChartType, setSelectedChartType] = useState('Shown As Bar')

  const campaignPOIMileageData = useMemo(() => {
    trips.map((t) => t.safe_coordinates).flatMap((c) => c)
    const data: { name: string; value: number }[] = []

    // Count the total mileage of the campaign trips
    const totalMileage = trips.reduce((total, trip) => total + trip.distance, 0)

    campaignPois.forEach((poi) => {
      // Count the number of points within the target range
      const pointsWithinRange = trips
        .flatMap((t) => t.safe_coordinates)
        .filter((coord) => {
          const tripPoint: Point = { latitude: coord[0], longitude: coord[1] }
          const poiPoint: Point = { latitude: poi.coordinate[0], longitude: poi.coordinate[1] }
          return isWithinRadius(tripPoint, poiPoint, poi.radius)
        }).length

      // Calculate ratio
      const totalPoints = trips.flatMap((t) => t.safe_coordinates).length
      const ratio = totalPoints > 0 ? pointsWithinRange / totalPoints : 0
      const mileage = Number((totalMileage * ratio).toFixed(1))
      data.push({ name: poi.name, value: mileage })
    })
    data.sort((a, b) => b.value - a.value)
    return data.length > 0 ? data : DEFAULT_DATA
  }, [trips, campaignPois])

  const campaignPOIInTargetMileageData = useMemo(() => {
    trips.map((t) => t.safe_coordinates).flatMap((c) => c)
    const data: { name: string; value: number }[] = []

    // Count the total hours of the campaign trips
    const totalMileage = trips.reduce((total, trip) => total + trip.distance, 0)
    let accumulatedMileage = 0
    campaignPois.forEach((poi) => {
      // Count the number of points within the target range
      const pointsWithinRange = trips
        .flatMap((t) => t.safe_coordinates)
        .filter((coord) => {
          const tripPoint: Point = { latitude: coord[0], longitude: coord[1] }
          const poiPoint: Point = { latitude: poi.coordinate[0], longitude: poi.coordinate[1] }
          return isWithinRadius(tripPoint, poiPoint, poi.radius)
        }).length

      // Calculate ratio
      const totalPoints = trips.flatMap((t) => t.safe_coordinates).length
      const ratio = totalPoints > 0 ? pointsWithinRange / totalPoints : 0
      const mileage = Number((totalMileage * ratio).toFixed(1))
      accumulatedMileage += mileage
    })
    data.push(
      { name: 'Mileage In Target Area', value: Number(accumulatedMileage.toFixed(1)) },
      {
        name: 'Mileage Not In Target Area',
        value: Number((totalMileage - accumulatedMileage).toFixed(1)),
      },
    )
    data.sort()
    return data.length > 0 ? data : DEFAULT_DATA
  }, [trips, campaignPois])

  return (
    <div className={cx('container')}>
      <Segmented
        className={cx('segmented')}
        options={['Shown As Bar', 'Shown As Pie']}
        value={selectedChartType}
        onChange={(v) => setSelectedChartType(v as string)}
      />
      <div className={cx('chart-container')}>
        {selectedChartType === 'Shown As Bar' ? (
          <ResponsiveContainer
            minHeight={chartMinHeight}
            maxHeight={2 * chartMinHeight}
            width='100%'
            aspect={2}
          >
            <BarChart
              data={campaignPOIMileageData}
              margin={{ bottom: 50, left: 50, right: 20, top: 20 }}
            >
              <XAxis
                dataKey='name'
                interval={0}
                tick={{ fontSize: 12 }}
                angle={-35}
                textAnchor='end'
                height={90} // Increase the height of the x-axis to accommodate rotated labels
              />
              <YAxis
                domain={[0, (dataMax: number) => Math.ceil(dataMax * 1.1)]}
                tickFormatter={(value) => `${value} km`}
                width={80}
              />
              <Legend verticalAlign='top' height={36} />
              <Tooltip />
              <Bar
                fill='#82ca9d'
                dataKey='value'
                name='Mileage Spent'
                label={{ position: 'top', fontSize: '110%' }}
              />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <div className={cx('pie-charts-container')}>
            <ResponsiveContainer minHeight={chartMinHeight}>
              <PieChart width={chartMinWidth} height={chartMinHeight}>
                <Pie
                  cx='45%'
                  data={campaignPOIInTargetMileageData}
                  labelLine={false}
                  outerRadius={chartMinRadius}
                  fill='#8884d8'
                  dataKey='value'
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {campaignPOIInTargetMileageData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    </div>
  )
}

export default MileageInTargetChart
