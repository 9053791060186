import { Alert, Button, Card, Input } from 'antd'
import classNames from 'classnames/bind'
import { KeyboardEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import PageContainer from '@/components/PageContainer'
import { useEnsureAppUsers } from '@/hooks/app-user'
import { useCurrentUser } from '@/hooks/user'

import AppUserDrawer from '../AppUserDrawer'

import styles from './index.module.scss'
import UsersList from './UsersList'

const cx = classNames.bind(styles)

const AppUsers = () => {
  useEnsureAppUsers()
  const navigate = useNavigate()
  const user = useCurrentUser()
  const [searchText, setSearchText] = useState('')
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [lastClickedUid, setLastClickedUid] = useState('')

  const onPressEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    const currentSearchText = event?.currentTarget?.value?.toLowerCase()
    if (currentSearchText != null && currentSearchText !== searchText)
      setSearchText(currentSearchText)
  }

  const onSearch = (value: string) => {
    const currentSearchText = value?.toLowerCase()
    if (currentSearchText != null && currentSearchText !== searchText)
      setSearchText(currentSearchText)
  }

  const onUserClicked = (uid: string) => {
    setLastClickedUid(uid)
    setIsDrawerOpen(true)
  }

  return (
    <PageContainer title='Users'>
      {user?.is_arund_admin && (
        <Alert
          className={cx('alert')}
          message='Welcome Admin!'
          description="As Arund Admin, you can view all users' trip history here."
          type='info'
          action={
            <Button type='primary' onClick={() => navigate('/all-users-map')}>
              View
            </Button>
          }
        />
      )}
      <Card className={cx('list-card')}>
        <Input.Search
          allowClear
          enterButton
          placeholder='Search any user'
          onPressEnter={onPressEnter}
          onSearch={onSearch}
        />
        <UsersList filter={searchText} onUserClicked={onUserClicked} />
        <AppUserDrawer
          onClose={() => setIsDrawerOpen(false)}
          open={isDrawerOpen}
          uid={lastClickedUid}
        />
      </Card>
    </PageContainer>
  )
}

export default AppUsers
