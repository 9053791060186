import { Alert, Segmented } from 'antd'
import classNames from 'classnames/bind'
import { useState } from 'react'
import { TypeAnimation } from 'react-type-animation'

import loginBg from '@/assets/bg.gif'
import { ReactComponent as LogoFull } from '@/assets/logo-text-2.svg'

import styles from './index.module.scss'
import SignInForm from './SignInForm'
import SignUpForm from './SignUpForm'

const cx = classNames.bind(styles)

const Login = () => {
  const [isSignUp, setIsSignUp] = useState(false)
  const [error, setError] = useState<string>()

  return (
    <div className={cx('login')}>
      <div className={cx('background')}>
        <img className={cx('img')} src={loginBg} />
        <div className={cx('above-img')}>
          <LogoFull className={cx('logo')} />
          <TypeAnimation
            sequence={['Arund', 800, 'Arund Client', 200, 'Arund Client Portal', 3000]}
            style={{ fontSize: '7rem', color: 'white', fontWeight: 700 }}
            repeat={Infinity}
          />
        </div>
      </div>
      <div className={cx('form-container')}>
        {error && <Alert message={error.toString()} type='error' showIcon closable />}
        <Segmented
          className={cx('segmented')}
          options={['Log In', 'Sign Up']}
          value={isSignUp ? 'Sign Up' : 'Log In'}
          onChange={(v) => setIsSignUp(v === 'Sign Up')}
        />
        {isSignUp ? <SignUpForm setError={setError} /> : <SignInForm setError={setError} />}
      </div>
    </div>
  )
}

export default Login
