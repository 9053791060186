import { Form, Input } from 'antd'
import classNames from 'classnames/bind'

import { AppUser } from '@/types'

import styles from './UserDetails.module.scss'

const cx = classNames.bind(styles)

type Props = {
  user: AppUser
}

const UserDetails = ({ user }: Props) => {
  return (
    <div className={cx('container')}>
      <div className={cx('form')}>
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} layout='horizontal' disabled>
          <Form.Item label='Name'>
            <Input value={user.first_name + user.last_name} />
          </Form.Item>
          <Form.Item label='Email'>
            <Input value={user.email} />
          </Form.Item>
          <Form.Item label='Firebase ID'>
            <Input value={user.uid} />
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default UserDetails
