import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

import { useAuthContext } from './AuthProvider'

type Props = {
  children: ReactNode
  reverse?: boolean
}

/**
 * Component that redirects to login page if no user is logged in, and visa versa
 */
const ProtectedRoute = ({ children, reverse }: Props) => {
  const { isSignedIn } = useAuthContext()

  if (reverse) {
    return !isSignedIn ? <>{children}</> : <Navigate to='/' replace />
  }
  return isSignedIn ? <>{children}</> : <Navigate to='/login' replace />
}

export default ProtectedRoute
