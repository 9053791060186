import { QuestionCircleOutlined } from '@ant-design/icons'
import { Progress, Result, Statistic } from 'antd'
import classNames from 'classnames/bind'
import dayjs from 'dayjs'

import { Campaign } from '@/types'

import styles from './index.module.scss'

const cx = classNames.bind(styles)

type Props = {
  campaign?: Campaign
}

const CampaignStatus = ({ campaign }: Props) => {
  if (!campaign) {
    return (
      <div>
        <Result
          icon={<QuestionCircleOutlined />}
          title='No active campaign'
          extra='Get started by creating a campaign!'
        />
      </div>
    )
  }

  const percent =
    dayjs().diff(dayjs(campaign.start_date), 'days') /
    dayjs(campaign.end_date).diff(dayjs(campaign.start_date), 'days')

  if (dayjs().isAfter(campaign.start_date) && dayjs().isBefore(campaign.end_date)) {
    return (
      <div className={cx('container')}>
        <Progress percent={Math.floor(percent * 100)} />
        <div className={cx('row')}>
          <Statistic
            title='Days Since Start'
            value={`${dayjs().diff(dayjs(campaign.start_date), 'days')} Days`}
          />
          <Statistic
            title='Days Till Finish'
            value={`${dayjs(campaign.end_date).diff(dayjs(), 'days')} Days`}
          />
        </div>
      </div>
    )
  } else if (dayjs().isAfter(campaign.end_date)) {
    return (
      <div className={cx('container')}>
        <Progress percent={100} />
        <Result
          status='success'
          title='Campaign has finished'
          subTitle='This indicates the campaign is in the past. You can view the details of the campaign, however some historical trip data may not be available'
        />
      </div>
    )
  } else {
    return (
      <div className={cx('container')}>
        <Progress percent={0} />
        <Result
          status='info'
          title='Campaign has not started'
          subTitle='This indicates the campaign has not started. Check back after the target start date to view detailed data'
        />
      </div>
    )
  }
}

export default CampaignStatus
