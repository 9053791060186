import { Card, Tabs, TabsProps } from 'antd'
import classNames from 'classnames/bind'
import { useMemo } from 'react'
import { useMatch } from 'react-router-dom'

import PageContainer from '@/components/PageContainer'
import { useCampaignPoisById, useEnsureCampaignPOIs } from '@/hooks/campaign-poi'
import { useCampaignById, useEnsureCampaignById } from '@/hooks/campaigns'
import { useEnsureTripsForCampaign, useTripsForCampaign } from '@/hooks/trips'
import { useCurrentUser } from '@/hooks/user'

import CampaignAnalytics from './CampaignAnalytics'
import CampaignDescription from './CampaignDescription'
import CampaignDriversList from './CampaignDriversList'
import CampaignLocations from './CampaignLocations'
import styles from './index.module.scss'

const cx = classNames.bind(styles)

const CampaignDetail = () => {
  const match = useMatch('/campaigns/:campaignId')
  const id = match?.params.campaignId || ''
  const user = useCurrentUser()
  useEnsureCampaignById(id)
  useEnsureTripsForCampaign(id)
  useEnsureCampaignPOIs(id)
  const campaign = useCampaignById(id)
  const trips = useTripsForCampaign(id)
  const { campaignPois } = useCampaignPoisById(id)
  const isArundAdmin = user?.is_arund_admin

  const items: TabsProps['items'] = useMemo(() => {
    return [
      {
        key: 'description',
        label: 'Details',
        children: <CampaignDescription campaign={campaign} />,
      },
      {
        key: 'analytics',
        label: 'Analytics',
        children: (
          <CampaignAnalytics
            isAdmin={isArundAdmin}
            campaign={campaign}
            trips={trips}
            campaignPois={campaignPois}
          />
        ),
      },
      {
        key: 'locations',
        label: 'Locations',
        children: <CampaignLocations isAdmin={isArundAdmin} campaign={campaign} trips={trips} />,
      },
      isArundAdmin
        ? {
            key: 'Drivers',
            label: 'Drivers',
            children: [<CampaignDriversList key='campaignDriversList' campaignId={id} />],
          }
        : undefined,
    ].filter(Boolean) as TabsProps['items']
  }, [campaign, campaignPois, id, trips, isArundAdmin])

  if (!campaign) {
    return null
  }

  return (
    <PageContainer title='Campaign Detail'>
      <Card className={cx('main-card')}>
        <Tabs items={items} defaultActiveKey='description' />
      </Card>
    </PageContainer>
  )
}

export default CampaignDetail
