import { useCallback, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@/store'
import {
  createCampaign,
  fetchCampaign,
  fetchCampaigns,
  selectAllCampaigns,
  selectCampaignByIdCreator,
  selectCampaignsState,
  updateCampaign,
} from '@/store/campaigns'
import { Campaign } from '@/types'

export const useEnsureCampaigns = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchCampaigns())
  }, [dispatch])
}

export const useEnsureCampaignById = (id: string) => {
  const dispatch = useAppDispatch()
  const campaign = useCampaignById(id)

  useEffect(() => {
    if (!campaign) {
      dispatch(fetchCampaign({ id }))
    }
  }, [campaign, dispatch, id])
}

export const useCampaigns = () => {
  const { error, isLoading } = useAppSelector(selectCampaignsState)
  const campaigns = useAppSelector(selectAllCampaigns)

  return { campaigns, error, isLoading }
}

export const useCampaignById = (id: string) => {
  return useAppSelector(selectCampaignByIdCreator(id))
}

export const useCreateCampaign = () => {
  const dispatch = useAppDispatch()

  const createNewCampaign = useCallback(
    (fields: Partial<Campaign>) => dispatch(createCampaign({ fields })),
    [dispatch],
  )

  return createNewCampaign
}

export const useUpdateCampaign = () => {
  const dispatch = useAppDispatch()

  const updateExistingCampaign = useCallback(
    (fields: Partial<Campaign>) => dispatch(updateCampaign({ fields })),
    [dispatch],
  )

  return updateExistingCampaign
}
