import dayjs from 'dayjs'
import { useMemo } from 'react'

import PageSummary from '@/components/PageSummary'
import { useCampaigns } from '@/hooks/campaigns'

const CampaignSummary = () => {
  const { campaigns } = useCampaigns()

  const activeNumOfRunners = campaigns
    .filter((c) => dayjs(c.start_date).isBefore(dayjs()) && dayjs(c.end_date).isAfter(dayjs()))
    .reduce((acc, cur) => acc + cur.num_of_runners, 0)

  const summaryItems = useMemo(() => {
    return [
      {
        key: 'number_of_campaigns',
        description: 'Total Campaigns',
        main: `${campaigns.length} Campaigns`,
      },
      {
        key: 'impression',
        description: 'Daily Impression',
        main: `${activeNumOfRunners * 1000} Times`,
      },
      {
        key: 'exposure_per_day',
        description: 'Exposure Per Day',
        main: '24 Hr',
      },
      {
        key: 'number_of_runners',
        description: 'Active Drivers',
        main: `${activeNumOfRunners} Drivers`,
      },
    ]
  }, [activeNumOfRunners, campaigns.length])
  return <PageSummary items={summaryItems} />
}

export default CampaignSummary
