import { LeftOutlined } from '@ant-design/icons'
import { Button, Typography } from 'antd'
import classNames from 'classnames/bind'
import { ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import styles from './index.module.scss'

const cx = classNames.bind(styles)

type Props = {
  children: ReactNode
  title?: ReactNode
}

const PageContainer = ({ children, title }: Props) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const showBack = pathname.split('/').length > 2
  const showTitle = !!title
  const showHeader = showBack || showTitle

  return (
    <div className={cx('container')}>
      {showHeader && (
        <div className={cx('header')}>
          {showBack && (
            <Button size='small' icon={<LeftOutlined />} onClick={() => navigate(-1)} type='text'>
              Back
            </Button>
          )}
          {showTitle && (
            <Typography.Title level={1} style={{ margin: 0 }}>
              {title}
            </Typography.Title>
          )}
        </div>
      )}
      <div className={cx('content-container')}>{children}</div>
    </div>
  )
}

export default PageContainer
