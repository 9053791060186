import { Col, Row, Typography } from 'antd'
import classNames from 'classnames/bind'
import { ReactNode } from 'react'

import styles from './index.module.scss'

const cx = classNames.bind(styles)

type Props = {
  items: {
    description: ReactNode
    main: ReactNode
    key: string
  }[]
}

const PageSummary = ({ items }: Props) => {
  return (
    <Row gutter={16} justify='space-between'>
      {items.map((item) => (
        <Col key={item.key}>
          <div className={cx('cell')}>
            <Typography.Text type='secondary'>{item.description}</Typography.Text>
            <Typography.Text className={cx('text-main')}>{item.main}</Typography.Text>
          </div>
        </Col>
      ))}
    </Row>
  )
}

export default PageSummary
