import { Card } from 'antd'
import classNames from 'classnames/bind'
import dayjs from 'dayjs'
import GridLayout, { Layout } from 'react-grid-layout'
import useMeasure from 'react-use-measure'

import CampaignStatus from '@/components/CampaignStatus'
import PageContainer from '@/components/PageContainer'
import TipsAndTricks from '@/components/TipsAndTricks'
import TripChart from '@/components/TripChart'
import { useCampaigns } from '@/hooks/campaigns'
import { useEnsureTripsForCampaign, useTripsForCampaign } from '@/hooks/trips'

import styles from './index.module.scss'

const cx = classNames.bind(styles)

const LAYOUT: Layout[] = [
  { i: 'campaign-status', x: 0, y: 0, w: 2, h: 1 },
  { i: 'tips', x: 2, y: 0, w: 2, h: 1 },
  { i: 'trip-info', x: 0, y: 1, w: 4, h: 1.5 },
]

const ROW_HEIGHT = 400

const Overview = () => {
  const [containerRef, bounds] = useMeasure()
  const { campaigns } = useCampaigns()
  const activeCampaign = campaigns.find((c) => dayjs().isBefore(c.end_date))

  useEnsureTripsForCampaign(activeCampaign?.id || '')
  const trips = useTripsForCampaign(activeCampaign?.id || '')
  const activeCampaignName = activeCampaign ? activeCampaign.name : 'No Active Campaign'

  return (
    <PageContainer title='Overview'>
      <div className={cx('container')} ref={containerRef}>
        <GridLayout
          cols={4}
          margin={[12, 12]}
          rowHeight={ROW_HEIGHT}
          width={bounds.width}
          layout={LAYOUT}
          isDraggable={false}
          isResizable={false}
        >
          <Card key='campaign-status' className={cx('tile-card')} title={activeCampaignName}>
            <CampaignStatus campaign={activeCampaign} />
          </Card>
          <Card key='tips' className={cx('tile-card')} title='Tips & Tricks'>
            <TipsAndTricks />
          </Card>
          <Card
            key='trip-info'
            className={cx('main-card')}
            title={activeCampaignName}
            bodyStyle={{ height: ROW_HEIGHT * 1.5 - 100 }}
          >
            <TripChart trips={trips} />
          </Card>
        </GridLayout>
      </div>
    </PageContainer>
  )
}

export default Overview
