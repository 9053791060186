import { Drawer, DrawerProps, Result, Typography } from 'antd'

import UserLocations from '../AppUserDetail/UserLocations'

type Props = {
  uid?: string
} & DrawerProps

const AppUserDrawer = ({ open, onClose, uid }: Props) => {
  return (
    <Drawer closable={false} open={open} onClose={onClose} size='large'>
      {!uid ? (
        <Result title='Something went wrong' subTitle='Please try again later' />
      ) : (
        <>
          <Typography.Title level={3}>Locations</Typography.Title>
          <UserLocations uid={uid} />
        </>
      )}
    </Drawer>
  )
}

export default AppUserDrawer
