import { Button, Checkbox, Form, Input } from 'antd'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useCallback, useState } from 'react'

import { useAuthContext } from '@/components/AuthProvider'
import { auth } from '@/firebase-setup'

type Props = {
  setError: (err: string) => void
}

const SignInForm = ({ setError }: Props) => {
  const { setIsSignedIn } = useAuthContext()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const onLogin = useCallback(() => {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setIsSignedIn(true)
      })
      .catch((error) => setError(error.toString()))
  }, [email, password, setError, setIsSignedIn])

  return (
    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
      <Form.Item
        label='Email'
        name='email'
        rules={[{ required: true, message: 'Please enter your email address' }]}
      >
        <Input value={email} onChange={(e) => setEmail(e.target.value)} />
      </Form.Item>
      <Form.Item
        label='Password'
        name='password'
        rules={[{ required: true, message: 'Please enter your password' }]}
      >
        <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} />
      </Form.Item>
      <Form.Item name='remember' wrapperCol={{ offset: 6, span: 14 }}>
        <Checkbox>Remember me</Checkbox>
      </Form.Item>
      <Form.Item wrapperCol={{ push: 6, span: 12 }}>
        <Button type='primary' htmlType='submit' onClick={onLogin} block>
          Login
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SignInForm
