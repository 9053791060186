import { fetchAPI } from './base'

export const getTripsByCampaignAPI = (id: string) => {
  return fetchAPI(`trips?campaign_id=${id}`, {
    method: 'GET',
  })
}

export const getTripsByUserAPI = (uid: string) => {
  return fetchAPI(`trips?uid=${uid}`, {
    method: 'GET',
  })
}

export const getAllTripsAPI = () => {
  return fetchAPI('trips?show_all=true', {
    method: 'GET',
  })
}
