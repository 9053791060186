import { Button, Card, Typography } from 'antd'
import classNames from 'classnames/bind'
import { signOut } from 'firebase/auth'
import { useCallback } from 'react'

import PageContainer from '@/components/PageContainer'
import { auth } from '@/firebase-setup'

import styles from './index.module.scss'

const cx = classNames.bind(styles)

const Settings = () => {
  const signOutCurrentUser = useCallback(() => {
    signOut(auth).then(() => {
      auth.currentUser?.reload()
    })
  }, [])

  return (
    <PageContainer title='Settings'>
      <Card className={cx('card')}>
        <div className={cx('inner-container')}>
          <Typography.Title level={3}>Account Information</Typography.Title>
          {/* <div className={cx('section')}>
            <Button>Change Password</Button>
          </div> */}
          <div className={cx('section')}>
            <Button onClick={signOutCurrentUser} danger type='primary'>
              Sign Out
            </Button>
          </div>
        </div>
      </Card>
    </PageContainer>
  )
}

export default Settings
