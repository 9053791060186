import { Card, Input, Table, TableColumnsType } from 'antd'
import classNames from 'classnames/bind'
import { KeyboardEvent, useEffect, useMemo, useState } from 'react'

import { useCampaignById } from '@/hooks/campaigns'
import { AppUser } from '@/types'
import { getUserName } from '@/utils/app-user'

import styles from './index.module.scss'

const cx = classNames.bind(styles)

type DataType = {
  key: string
  name: string
}

type UserViewModel = {
  name: string
  key: string
  phone: string
  email: string
  city: string
}

type Props = {
  campaignId: string
}

class UserViewModelComparator {
  // assumes 'pattern' is already in lower case

  public static matchesName(viewModel: UserViewModel, pattern: string) {
    if (viewModel?.name == null || pattern == null) return false

    return viewModel.name.toLowerCase().includes(pattern)
  }

  public static matchesEmail(viewModel: UserViewModel, pattern: string) {
    if (viewModel?.name == null || pattern == null) return false

    return viewModel.email.toLowerCase().includes(pattern)
  }

  public static matchesPhoneNumber(viewModel: UserViewModel, pattern: string) {
    if (viewModel?.name == null || pattern == null) return false

    return viewModel.phone.toLowerCase().includes(pattern)
  }

  public static matchesCity(viewModel: UserViewModel, pattern: string) {
    if (viewModel?.name == null || pattern == null) return false

    return viewModel.city.toLowerCase().includes(pattern)
  }
}

const columns: TableColumnsType<DataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Phone Number',
    dataIndex: 'phone',
  },
  {
    title: 'City',
    dataIndex: 'city',
  },
]

const CampaignDriversList = ({ campaignId }: Props) => {
  const { runners } = useCampaignById(campaignId)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchText, setSearchText] = useState('')

  const onPressEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    const currentSearchText = event?.currentTarget?.value?.toLowerCase()
    if (currentSearchText != null && currentSearchText !== searchText)
      setSearchText(currentSearchText)
  }

  const onSearch = (value: string) => {
    const currentSearchText = value?.toLowerCase()
    if (currentSearchText != null && currentSearchText !== searchText)
      setSearchText(currentSearchText)
  }
  const createUserViewModels = (users: AppUser[], filter?: string) => {
    let viewModels: UserViewModel[] = users.map((u) => ({
      name: getUserName(u),
      key: u.uid,
      email: u.email,
      phone: u.phone,
      city: u.city,
    }))

    if (filter) {
      viewModels = viewModels.filter(
        (vm) =>
          UserViewModelComparator.matchesName(vm, filter) ||
          UserViewModelComparator.matchesEmail(vm, filter) ||
          UserViewModelComparator.matchesCity(vm, filter) ||
          UserViewModelComparator.matchesPhoneNumber(vm, filter),
      )
    }

    setPageNumber(1)
    return viewModels
  }

  const data: DataType[] = useMemo(
    () => createUserViewModels(runners, searchText),
    [runners, searchText],
  )

  useEffect(() => {
    setPageNumber(1)
  }, [runners])

  return (
    <Card className={cx('list-card')}>
      <Input.Search
        allowClear
        enterButton
        placeholder='Search any user'
        onPressEnter={onPressEnter}
        onSearch={onSearch}
      />

      <Table
        columns={columns}
        dataSource={data}
        pagination={{ current: pageNumber, onChange: (page) => setPageNumber(page) }}
      />
    </Card>
  )
}

export default CampaignDriversList
