import { Card } from 'antd'
import classNames from 'classnames/bind'

import PageContainer from '@/components/PageContainer'

import CampaignList from './CampaignList'
import CampaignSummary from './CampaignSummary'
import styles from './index.module.scss'

const cx = classNames.bind(styles)

const Campaigns = () => {
  return (
    <PageContainer title='Campaigns'>
      <Card className={cx('summary-card')}>
        <CampaignSummary />
      </Card>
      <Card className={cx('list-card')}>
        <CampaignList />
      </Card>
    </PageContainer>
  )
}

export default Campaigns
