import HeatMapView from '@/components/HeatMapView'
import { Campaign, Trip } from '@/types'
type Props = {
  campaign: Campaign
  trips: Trip[]
  isAdmin: boolean | undefined
}

const CampaignLocations = ({ campaign, trips }: Props) => {
  return (
    <div style={{ marginBottom: '10px' }}>
      <HeatMapView trips={trips} campaignId={campaign.id} />
    </div>
  )
}

export default CampaignLocations
