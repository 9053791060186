import { CampaignPoi } from '@/types'

import { fetchAPI } from './base'

export const createCampaignPoiAPI = (fields: Partial<CampaignPoi>) => {
  const body = JSON.stringify(fields)
  return fetchAPI('campaign-poi', {
    body,
    method: 'POST',
  })
}

export const updateCampaignPoiAPI = (fields: Partial<CampaignPoi>) => {
  const body = JSON.stringify(fields)
  return fetchAPI(`campaign-poi/${fields.id}`, {
    body,
    method: 'PUT',
  })
}

export const getCampaignPOIsAPIByCampaign = (id: string) => {
  return fetchAPI(`campaign-poi?campaign_id=${id}`, {
    method: 'GET',
  })
}

export const deleteCampaignPOIAPI = (id: string) => {
  return fetchAPI(`campaign-poi/${id}`, {
    method: 'DELETE',
  })
}
