import { auth } from '@/firebase-setup'

// const BASE_URL = 'http://localhost:8000/api'
const BASE_URL = 'https://api.arund.ca/api'

export const fetchAPI = (path: RequestInfo, options: RequestInit, extraHeaders?: HeadersInit) =>
  auth.currentUser?.getIdToken().then((token) => {
    return fetch(`${BASE_URL}/${path}`, {
      headers: {
        Authorization: token,
        'Arund-Client': 'client',
        'Content-Type': 'application/json',
        ...extraHeaders,
      },
      ...options,
    }).then((res) => {
      if (!res.ok) {
        return res.text().then((text) => {
          console.error(text)
          throw new Error(text)
        })
      }
      return res.json().catch((e) => {
        console.error(JSON.stringify(e))
        throw new Error('Unknown error when parsing error json')
      })
    })
  })
