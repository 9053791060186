import { HeatMapOutlined, RocketOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Input, Layout, MenuProps, theme } from 'antd'
import classNames from 'classnames/bind'
import { useMemo } from 'react'
import { Outlet, useMatch, useNavigate } from 'react-router-dom'

import { useEnsureCampaigns } from '@/hooks/campaigns'
import { useCurrentUser } from '@/hooks/user'

import CustomSider from './CustomSider'
import styles from './index.module.scss'

type MenuItem = Required<MenuProps>['items'][number]

const cx = classNames.bind(styles)

const MENU_ITEMS: MenuItem[] = [
  { key: 'overview', icon: <UserOutlined />, label: 'Overview' },
  { key: 'campaigns', icon: <RocketOutlined />, label: 'Campaigns' },
]

const ADMIN_MENU_ITEMS: MenuItem[] = [
  { key: 'users', icon: <UserOutlined />, label: 'Users' },
  { key: 'all-users-map', icon: <HeatMapOutlined />, label: 'All Users HeatMap' },
]

const Main = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const match = useMatch({ path: '/:path/*' })
  const navigate = useNavigate()

  useEnsureCampaigns()
  const user = useCurrentUser()

  const menuItems = useMemo(() => {
    if (user?.is_arund_admin) {
      return MENU_ITEMS.concat(ADMIN_MENU_ITEMS)
    }
    return MENU_ITEMS
  }, [user?.is_arund_admin])

  return (
    <Layout hasSider>
      <CustomSider menuItems={menuItems} selectedMenuKey={match?.params.path || 'overview'} />
      <Layout style={{ marginLeft: 240 }}>
        <Layout.Header style={{ paddingInline: 16, backgroundColor: colorBgContainer }}>
          <div className={cx('header')}>
            <Input.Search style={{ width: 400 }} placeholder='Search for anything' />
            <Button style={{ marginLeft: 'auto' }} icon={<UserOutlined />} type='text' />
            <Button
              style={{ marginLeft: 16 }}
              icon={<SettingOutlined />}
              type='text'
              onClick={() => navigate('settings')}
            />
          </div>
        </Layout.Header>
        <Layout.Content
          style={{
            minHeight: 'calc(100vh - 130px)',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#f0f2f5',
          }}
        >
          <Outlet />
        </Layout.Content>
        <Layout.Footer style={{ textAlign: 'center' }}>Arund Inc @ 2024</Layout.Footer>
      </Layout>
    </Layout>
  )
}

export default Main
