import { Button, Form, Input } from 'antd'
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { useCallback, useState } from 'react'

import { useAuthContext } from '@/components/AuthProvider'
import { auth } from '@/firebase-setup'
import { useCreateUser } from '@/hooks/user'

type Props = {
  setError: (err: string) => void
}

const SignUpForm = ({ setError }: Props) => {
  const { setIsSignedIn } = useAuthContext()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const createUser = useCreateUser()

  const onSignUp = useCallback(() => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(({ user }) => {
        sendEmailVerification(user)
        setIsSignedIn
        createUser({
          uid: user.uid,
          email: email,
        })
      })
      .catch((error) => setError(error))
  }, [email, password, setError, setIsSignedIn, createUser])

  return (
    <Form labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
      <Form.Item
        label='Email'
        name='email'
        rules={[{ required: true, message: 'Please enter your email address' }]}
      >
        <Input value={email} onChange={(e) => setEmail(e.target.value)} />
      </Form.Item>
      <Form.Item
        label='Password'
        name='password'
        rules={[{ required: true, message: 'Please enter your password' }]}
      >
        <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} />
      </Form.Item>
      <Form.Item
        label='Confirm Password'
        name='confirm password'
        rules={[{ required: true, message: 'Please re-enter your password' }]}
      >
        <Input.Password
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ push: 8, span: 12 }}>
        <Button type='primary' htmlType='submit' onClick={onSignUp} block>
          Sign Up
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SignUpForm
