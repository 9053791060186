import { createBrowserRouter, Navigate } from 'react-router-dom'

import ProtectedRoute from './components/ProtectedRoute'
import AllUsersMap from './pages/AllUsersMap'
import AppUserDetail from './pages/AppUserDetail'
import AppUsers from './pages/AppUsers'
import CampaignCreate from './pages/CampaignCreate'
import CampaignDetail from './pages/CampaignDetail'
import Campaigns from './pages/Campaigns'
import Login from './pages/Login'
import Main from './pages/Main'
import Overview from './pages/Overview'
import Settings from './pages/Settings'

const router = createBrowserRouter([
  {
    path: 'login',
    element: (
      <ProtectedRoute reverse>
        <Login />
      </ProtectedRoute>
    ),
  },
  {
    element: (
      <ProtectedRoute>
        <Main />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '/*',
        index: true,
        element: <Navigate to='/overview' />,
      },
      {
        path: 'campaigns',
        element: <Campaigns />,
      },
      {
        path: 'campaigns/new',
        element: <CampaignCreate />,
      },
      {
        path: 'campaigns/:campaignId',
        element: <CampaignDetail />,
      },
      {
        path: 'users',
        element: <AppUsers />,
      },
      {
        path: 'users/:uid',
        element: <AppUserDetail />,
      },
      {
        path: 'settings',
        element: <Settings />,
      },
      {
        path: 'overview',
        element: <Overview />,
      },
      {
        path: 'all-users-map',
        element: <AllUsersMap />,
      },
    ],
  },
])

export default router
