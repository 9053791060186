import { Spin } from 'antd'
import classNames from 'classnames/bind'
import { onAuthStateChanged } from 'firebase/auth'
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'

import { auth } from '@/firebase-setup'
import { useFetchUser } from '@/hooks/user'

import styles from './index.module.scss'

const cx = classNames.bind(styles)

type AuthState = {
  isSignedIn: boolean
  setIsSignedIn: (b: boolean) => void
}

const AuthContext = createContext<AuthState>({
  isSignedIn: false,
  setIsSignedIn: () => {
    return
  },
})

type Props = {
  children: ReactNode
}

const AuthProvider = ({ children }: Props) => {
  const fetchUser = useFetchUser()
  const [isSignedIn, setIsSignedIn] = useState<boolean>()

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUser(user.uid)
      }
      setIsSignedIn(!!user)
    })
  }, [fetchUser])

  const value = useMemo(() => {
    return {
      isSignedIn: isSignedIn || false,
      setIsSignedIn,
    }
  }, [isSignedIn])

  if (isSignedIn === undefined) {
    return (
      <div className={cx('container')}>
        <Spin tip='Loading' size='large' />
      </div>
    )
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider

export const useAuthContext = () => useContext(AuthContext)
