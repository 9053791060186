import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { getAllTripsAPI, getTripsByCampaignAPI, getTripsByUserAPI } from '@/api'
import { Trip } from '@/types'

import { RootState } from '.'

type TripByEndTime = { [endtime: string]: Trip }
type TripForCampaignStorage = { [campaignId: string]: Trip[] }
type TripForUserStorage = { [userId: string]: TripByEndTime }

type TripState = {
  error: string | null
  isLoading: boolean
  tripsForCampaign: TripForCampaignStorage
  tripsForUser: TripForUserStorage
  allTrips: Trip[]
}

const slice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(fetchTripsForCampaign.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchTripsForCampaign.fulfilled, (state, action) => {
        state.error = null
        state.isLoading = false
        state.tripsForCampaign[action.meta.arg.campaignId] = action.payload
        state.allTrips = action.payload
      })
      .addCase(fetchTripsForCampaign.rejected, (state, action) => {
        state.error = action.payload || ''
        state.isLoading = false
      })
      .addCase(fetchTripsForUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchTripsForUser.fulfilled, (state, action) => {
        state.error = null
        state.isLoading = false
        state.tripsForUser[action.meta.arg.uid] = action.payload.reduce((acc, cur) => {
          acc[cur.end_time] = cur
          return acc
        }, {} as TripByEndTime)
      })
      .addCase(fetchAllTrips.rejected, (state, action) => {
        state.error = action.payload || ''
        state.isLoading = false
      })
      .addCase(fetchAllTrips.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchAllTrips.fulfilled, (state, action) => {
        state.error = null
        state.isLoading = false
        state.allTrips = action.payload
      })
      .addCase(fetchTripsForUser.rejected, (state, action) => {
        state.error = action.payload || ''
        state.isLoading = false
      })
  },
  initialState: {
    tripsForCampaign: {},
    tripsForUser: {},
    allTrips: [],
    error: null,
    isLoading: false,
  } as TripState,
  name: 'Trips',
  reducers: {},
})

export const fetchTripsForCampaign = createAsyncThunk<
  Trip[],
  { campaignId: string },
  { rejectValue: string }
>('trips/fetch-campaign', async ({ campaignId }, thunkApi) => {
  try {
    return (await getTripsByCampaignAPI(campaignId)) as Trip[]
  } catch (e) {
    if (e instanceof Error) {
      return thunkApi.rejectWithValue(e.message)
    }
    return thunkApi.rejectWithValue('Unknown error when fetching trips for campaign')
  }
})

export const fetchTripsForUser = createAsyncThunk<Trip[], { uid: string }, { rejectValue: string }>(
  'trips/fetch-user',
  async ({ uid }, thunkApi) => {
    try {
      return (await getTripsByUserAPI(uid)) as Trip[]
    } catch (e) {
      if (e instanceof Error) {
        return thunkApi.rejectWithValue(e.message)
      }
      return thunkApi.rejectWithValue('Unknown error when fetching trips for user')
    }
  },
)

export const fetchAllTrips = createAsyncThunk<Trip[], undefined, { rejectValue: string }>(
  'trips/fetch-all',
  async (_, thunkApi) => {
    try {
      return (await getAllTripsAPI()) as Trip[]
    } catch (e) {
      if (e instanceof Error) {
        return thunkApi.rejectWithValue(e.message)
      }
      return thunkApi.rejectWithValue('Unknown error when fetching all trips')
    }
  },
)

export const selectTripsState = (state: RootState) => state.trips

// const selectTripObjectsForCampaignCreator = (campaignId: string) =>
//   createSelector(selectTripsState, ({ tripsForCampaign }) => tripsForCampaign[campaignId])

export const selectTripsForCampaignCreator = (campaignId: string) =>
  createSelector(selectTripsState, ({ tripsForCampaign }) =>
    Object.values(tripsForCampaign[campaignId] || {}),
  )

// Uncomment this when backend changes on end_time finished
// export const selectTripsForCampaignByEndTimeCreator = (campaignId: string, end: string) =>
//   createSelector(
//     selectTripObjectsForCampaignCreator(campaignId),
//     (tripsForCampaign) => tripsForCampaign[end],
//   )

const selectTripObjectsForUserCreator = (uid: string) =>
  createSelector(selectTripsState, ({ tripsForUser }) => tripsForUser[uid])

export const selectTripsForUserCreator = (uid: string) =>
  createSelector(selectTripsState, ({ tripsForUser }) => Object.values(tripsForUser[uid] || {}))

export const selectTripsForUserByEndTimeCreator = (uid: string, end: string) =>
  createSelector(selectTripObjectsForUserCreator(uid), (tripsForUser) => tripsForUser[end])

export default slice.reducer
